import { render, staticRenderFns } from "./EmployesView.vue?vue&type=template&id=4c6d7a28&"
import script from "./EmployesView.vue?vue&type=script&lang=js&"
export * from "./EmployesView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports